.select-self-register {
  .css-b62m3t-container {
    .css-13cymwt-control {
      padding: 4px !important;
      border-radius: 30px !important;
      border: 0px !important;
    }
  }
}

.select-balance-cpm {
  .css-b62m3t-container {
    .css-13cymwt-control {
      padding: 4px !important;
      border-radius: 6px !important;
      border-width: 1px !important;
      border-color: #9A9999 !important;

      .css-1fdsijx-ValueContainer {
        text-align: start !important;

        .css-1dimb5e-singleValue {
          text-align: start !important;
        }
      }
    }

    .css-1nmdiq5-menu {
      text-align: start !important;
    }
  }
}

.react-tagsinput-input {
  margin-bottom: 0px !important;
}

.tags-select {
  .select__control {
    padding: 6px;
    border-radius: 30px;
    border: 0px;
  }
}
.custom-phone-input {
  .PhoneInputInput {
    border-radius: 6px !important;
    padding: 12px !important;
    border: none !important;
    background-color: #FDFDFD !important;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  }

  .PhoneInput--focus {
    .PhoneInputInput {
      outline: rgb(77 182 172 / 0.25) solid 3px !important;
    }
  }
}

.custom-image {
  &:hover .overlay {
    opacity: 1 !important;
  }
}

.overlay {
  transform: translateY(-244px) !important;
  transition: 0.5s !important;
}

.custom-date-picker {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        border-radius: 6px !important;
        padding: 12px !important;
        border: none !important;
        background-color: #FDFDFD !important;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
        width: 100% !important;
      }
    }
  }
}

.custom-date-picker-new {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        border-radius: 6px !important;
        font-size: 14px !important;
        padding: 12px !important;
        border: solid !important;
        border-color: #9A9999 !important;
        border-width: 0.5px !important;
        background-color: white !important;
        outline-color: #9A9999 !important;
        width: 100% !important;
      }

      :focus {
        outline-style: solid !important;
      }
    }
  }
}

// desktop
@media only screen and (min-width: 768px) {
  .custom-phone-input {
    .PhoneInputInput {
      border-radius: 6px !important;
      padding: 1rem !important;
      border: none !important;
      background-color: #FDFDFD !important;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    }

    .PhoneInput--focus {
      .PhoneInputInput {
        outline: rgb(77 182 172 / 0.25) solid 3px !important;
      }
    }
  }

  .custom-date-picker {
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          border-radius: 6px !important;
          padding: 1rem !important;
          border: none !important;
          background-color: #FDFDFD !important;
          box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
          width: 100% !important;
        }
      }
    }
  }

  .custom-date-picker-new {
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          border-radius: 6px !important;
          font-size: 14px !important;
          padding: 12px !important;
          border: solid !important;
          border-color: #9A9999 !important;
          border-width: 0.5px !important;
          background-color: white !important;
          outline-color: #9A9999 !important;
          width: 100% !important;
        }

        :focus {
          outline-style: solid !important;
        }
      }
    }
  }
}

.select-edit-profile {
  .css-b62m3t-container {
    .css-13cymwt-control {
      padding: 4px !important;
      border-radius: 10px !important;
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .centered-items {
    @apply flex flex-row justify-center items-center;
  }
  .space-between {
    @apply flex flex-row justify-between items-center;
  }
  .active-item {
    @apply border border-[#039f79];
  }
  .btn-gradient {
    @apply bg-gradient-to-b from-[#16C894] to-[#04A47C] text-white md:text-base text-sm font-semibold w-full p-[12px];
  }
  .btn-gradient2 {
    @apply bg-gradient-to-b from-[#16C894] to-[#04A47C];
  }
  .summary-page-text {
    @apply md:text-base text-sm text-[#252525] rounded-[20px] font-medium mb-0;
  }
}

/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

[role="dialog"] {
  z-index: 10 !important;
}

.css-16xfy0z-control {
  height: 44px;
}

.select-self-register.css-b62m3t-container.css-16xfy0z-control {
  padding: 4px;
  border-radius: 30px;
  border: 0px;
}
.custom-input:focus{
  outline: thin solid var(--dynamic-color)!important
}
.animate-top {
  position: relative;
  animation: animatetop 0.45s;
}
@keyframes animatetop {
  from {
    top: -100px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}
.image-cover-style {
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 0.4rem;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background-color: #c0bebe;
  border-radius: 20px;
}
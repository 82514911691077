.custom-scan-barcode {
  #html5qr-code-full-region {
    border: transparent !important;

    #html5qr-code-full-region__dashboard {
      #html5qr-code-full-region__dashboard_section {
        div {
          #html5qr-code-full-region__dashboard_section_csr {
            span {
              button {
                background-color: #039F79;
                border-radius: 6px;
                padding: 8px 12px;
                color: white;
                // display: none !important;
              }
            }

            // span {
            //   select {
            //     display: none !important;
            //   }
            // }

            div {
              button {
                background-color: #039F79;
                border-radius: 6px;
                padding: 8px 12px;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-submit {
  color: #fff;
  background-color: #00D19F;
  background-color: cutom-color;
  border-color: #00D19F;
  border-color: cutom-cor;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-primary-white-text {
  color: #fff !important;
}

.a-breadcrumb {
  color: #000 !important;
  text-decoration: none !important;
}

.a-breadcrumb>a {
  color: #000 !important;
  text-decoration: none !important;
}

.btn-primary-reauth {
  padding: 20px !important
}

#html5qr-code-full-region__scan_region {
  display: flex !important;
  justify-content: center !important;
}

/* .swiper-slide {
  width: 100% !important;
} */
.swiper-slide-40 {
  width: 40% !important;
}

.swiper-slide-100 {
  width: 100% !important;
}

.swiper-slide-80 {
  width: 80% !important;
}

.swiper-slide-90 {
  width: 90% !important;
}

.swiper-slide-siakad img {
  display: flex;
  width: auto !important;
  height: auto !important;
  object-fit: none;
}

.swiper-slide-auto {
  width: auto !important;
  height: 60px !important;
}

.swiper-slide-info-mobile{
  width:20rem !important;
  height: 20rem !important;
}

.swiper-slide-info-web{
  width:23rem !important;
  height: 23rem !important;
}

.button {
  border-radius: 15px !important;
  padding: 15px !important;
  border: none !important;
}

.custom-form-select {
  border-radius: 12px !important;
  padding: 15px !important;
  border: none !important;
  color: #8F8F8F !important;
  background: #F4F4F4 !important;
}

.custom-form-control {
  border-radius: 30px !important;
  padding: 15px !important;
  border: none !important;
  background-color: #FDFDFD !important;
}

.form-control2 {
  border-radius: 8px !important;
  padding: 15px !important;
  border: none !important;
  background-color: #F4F4F4 !important;

}

.form-select2 {
  border-radius: 8px !important;
  padding: 15px !important;
  border: none !important;
  background-color: #F4F4F4 !important;
  color: #000 !important;
}

.form2 {
  background-color: #fff !important;
  padding: 24px;
  border-radius: 12px
}

.text-decoration-none {
  text-decoration: none !important;
}

.btn-link-change-password {
  font-family: "Poppins-500" !important;
  font-size: 20px !important;
  color: #007BB4 !important;
}

.button-info {
  background-color: #00D19F !important;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0);
  font-family: "Poppins-700";
}

.btn-link {
  color: #0466C8;
  font-family: "Poppins-300";
  font-size: 15px;
  text-decoration: none !important;
}

.btn-link:hover {
  color: #0466C8;
  font-family: "Poppins-300";
  font-size: 15px;
  text-decoration: none !important;
}

.button-info:hover {
  background-color: #00D19F !important;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0);
  font-family: "Poppins-700";
}

.btn-sm {
  border-radius: 6px !important;
  padding: 5px !important;
  font-size: 14px !important;
  height: 35px !important;
}

.text-14 {
  font-size: 14px !important;
}

.react-multiple-carousel__arrow {
  z-index: 10 !important;
}

.bold-600 {
  font-weight: 600;
}

.br-6 {
  border-radius: 6px !important;
}

.btn-primary {
  background-color: #00D19F !important;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0) !important;
  font-family: "Poppins-700";
  border-radius: 30px !important;
  /* padding: 20px 0px !important; */
}

.btn-primary:hover {
  background-color: #00D19F !important;
  color: #fff;
  border: none !important;
  font-family: "Poppins-700";
}

.btn-secondary {
  background-color: #FFD400 !important;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0) !important;
  font-family: "Poppins-700";
  font-size: 16;
  border-radius: 30px !important;
  /* padding: 20px 0px !important */
}

.btn-secondary:hover {
  background-color: #FFD400 !important;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0) !important;
  font-family: "Poppins-700";
  font-size: 16;
}

.btn-outline-light {
  background-color: #534f3c00 !important;
  color: #fff;
  padding: 0 !important;
  border: 1px solid rgb(255, 255, 255) !important;
  font-family: "Poppins-700";
  font-size: 16;
}

.btn-outline-light:hover {
  background-color: #534f3c00 !important;
  color: #fff;
  padding: 0 !important;
  border: 1px solid rgb(255, 255, 255) !important;
  font-family: "Poppins-700";
  font-size: 16;
}

.divider {
  border-bottom: 1px solid #C4C4C4;
  width: 100%;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.desktop-background {
  backround-image: url("../../assets/images/desktopBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.card-body-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  height: 100px;
}

.card-img-product {
  border-radius: 12px !important;
  display: block;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 190px;
  margin: 0px auto;
}

.card-product {
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 12px !important;
  border: 0 !important;
  /* width: 150px; */
  height: 100%;
  /* max-height: 200px; */
}

.card-title-product {
  font-size: 10px !important;
  font-weight: 300 !important;
}

.card-text-product {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.bg-product {
  background-color: #26BF64 !important;
  padding-top: 24px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 234px;
}

/* .nav-link2 {
  display: block;
  color: #0d6efd;
  background-color: rgba(255, 255, 255, 0) !important;
  text-decoration: none;
  padding-right: 0.5rem;
    padding-left: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
} */

.nav-tabs {
  justify-content: center;
  margin-bottom: 14px !important;
  border: 0px !important;

}

/* .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff !important;
  background-color: #00D19F !important;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;

  margin-left: 8px;
    margin-right: 8px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-radius: 8px !important;
  padding: 12px 32px 12px 32px !important;
  background-color: #fff !important;
  color: #c4c4c4 !important;
} */

.navbar-ppob {
  background-color: #007BB4;
}

.h1-component-not-found {
  font-size: 32px;
  font-family: "Poppins-700";
}

.h3-component-not-found {
  font-family: "Poppins-400";
  font-size: 20px;
  color: #848383;
}

/* .nav-link{
  margin-left: 8px;
  margin-right: 8px;
  background-color: #fff !important;
  color: #c4c4c4 !important;
  padding: 12px 32px 12px 32px !important;
  font-family: 'Poppins-700';
  font-size: 16px;
}
.nav-link.active {
  background-color: #00D19F !important;
  color: #fff !important;
  font-family: 'Poppins-700';
  font-size: 16px;
  border-radius: 8px !important;
} */

@media only screen and (max-width: 360) {
  .card-product {
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 12px !important;
    border: 0 !important;
    width: 100px;
    height: 100%;
  }
}

@media only screen and (max-width: 455px) {
  .card-body-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    height: 100px;
  }

  .card-img-product {
    border-radius: 12px !important;
    display: block;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    margin: 0px auto;
  }

  .h1-component-not-found {
    font-size: 20px;
    font-family: "Poppins-700";
  }

  .h3-component-not-found {
    font-family: "Poppins-400";
    font-size: 16px;
    color: #848383;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #iconMenu {
    /* display: inline-flex; */
    /* flex-direction: row */
    width: null;
    margin: auto;
    padding: 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bg-product {
    background-color: #26BF64 !important;
    border-radius: 12px;
    padding-top: 24px;
    position: absolute;
    left: 0;
    width: 86%;
    margin-left: 28px;
    height: 234px;
  }


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .bg-product {
    background-color: #26BF64 !important;
    border-radius: 12px;
    padding-top: 24px;
    position: absolute;
    left: 0;
    width: 86%;
    margin-left: 28px;
    height: 234px;
  }

  .card-body-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    height: 150px;
  }

  .card-img-product {
    border-radius: 12px !important;
    display: block;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 190px;
    margin: 0px auto;
  }

  .card-product {
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 12px !important;
    border: 0 !important;
    width: 100%;
    height: 100%;
    max-width: 200px;
  }

  .card-title-product {
    font-size: 13px !important;
    font-weight: 300 !important;
  }

  .card-text-product {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bg-product {
    background-color: #26BF64 !important;
    border-radius: 12px;
    padding-top: 24px;
    position: absolute;
    left: 0;
    width: 89%;
    margin-left: 18px;
    height: 262px;
  }

  .custom-navbar {
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: #F3F4FB;
    align-items: center;
  }

  .navbar-collapse-custom {
    background-color: #f3f4fb;
  }
}

@media only screen and (max-width: 991px) {
  .custom-navbar {
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: white;
    align-items: center;
  }

  .navbar-collapse-custom {
    background-color: white !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .bg-product {
    background-color: #26BF64 !important;
    border-radius: 12px;
    padding-top: 24px;
    position: absolute;
    left: 0;
    width: 87%;
    margin-left: 52px;
    height: 260px;
  }
}

@media only screen and (min-width: 1822px) {
  .bg-product {
    background-color: #26BF64 !important;
    border-radius: 12px;
    padding-top: 24px;
    position: absolute;
    left: 0;
    width: 69%;
    margin-left: 250px;
    height: 260px;
  }
}


.btn-warning {
  background-color: #FF860D !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-family: "Poppins-700";
  font-size: 14px !important;
  /* padding: 20px 0px !important; */
}

.btn-success {
  background-color: #2BC569 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-family: "Poppins-700";
  font-size: 14px !important;
  padding: 8px !important;
}

.btn-outline-secondary {
  padding: 8px !important;
  border-radius: 5px !important;
}



.heart {
  font-size: 35px;
  color: rgb(182, 173, 173);
  margin-top: 7px;
  width: 70px;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;

}

.heart:hover {
  color: rgb(192, 39, 39);
}

.heart.active {
  color: rgb(192, 39, 39);
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 0.5px solid #c4c4c4;
}

.normal-link {
  color: var(--bs-body-color);
  text-decoration: none;
  cursor: pointer;
}

.normal-link:hover {
  color: var(--bs-body-color);
}

.btn-inside-input {
  display: flex;
  align-items: center;
  place-content: center;
}

.btn-inside-input {
  padding-right: 125px !important;
  width: 245 !important;
}

.btn-inside-input {
  position: relative !important;
  right: 30px !important;
  display: flex;
  place-content: center;
  align-items: center;
  z-index: 9 !important;
  width: 17px !important;
  height: 17px !important;
  padding: 10px !important;
  border-radius: 15px !important;
}

.btn-inside-input>span {
  position: relative !important;
  right: 110px !important;
  display: flex;
  place-content: center;
  align-items: center;
  z-index: 9 !important;
  width: 50px !important;
  /* height: 17px !important; */
  /* 
  padding: 10px !important;
  border-radius: 15px !important; */
}

.active-item {
  border: rgb(3, 159, 121) 1px solid;
}

/* .card-img-top{
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important ;
} */

/* .card{
  border-radius: 14px !important;
} */

#iconMenu {
  /* width: 0%; */
}

.marginSaldo {
  margin-left: 24;
  margin-right: 24;
}

.form-control-otp-forgot-password-web {
  border-radius: 5px !important;
  background-color: #E5E5E5 !important;
  padding: 20px !important;
  border: 0px !important
}

.btn-warning--otp-forgot-password-web {
  background-color: #FF860D !important;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0) !important;
  font-family: "Poppins-700";
  border-radius: 30px !important;
  padding: 20px 0px !important;
}

.tag-input-focused {
  border-color: #1AA7EC !important;
}

.react-tagsinput {
  border-radius: .375rem;
}
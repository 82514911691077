.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Contoh tinggi penuh layar */
  }
  
  .card {
    width: 300px; /* Atur lebar card sesuai kebutuhan Anda */
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); /* Efek shadow inset */
  }
  
  .card-content {
    /* Gaya konten card Anda */
  }
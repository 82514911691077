.form-control-cp-mobile {
  background-color: #fff !important;
  border-radius: 24px !important;
  height: 41px !important;
}

.carouselItem {
  //   padding-right: 40px;
}
.react-multi-carousel-item {
  //   max-width: 320px;
  //   min-width: 320px;
  //   padding: 10px;
}

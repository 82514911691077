.hDesc {
  min-width: 300px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  max-height: 200px;
  overflow: hidden;
  content: "";
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 100px, white);
  }
}
.hDescDesktop {
  min-width: 300px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  max-height: 200px;
  overflow: hidden;
  content: "";
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 50px, white);
  }
}
.hDescList {
  min-width: 300px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  max-height: 100px;
  overflow: hidden;
  content: "";
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 50px, white);
  }
}
.hDescListDesktop {
  min-width: 300px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  max-height: 100px;
  overflow: hidden;
  content: "";
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 100px, white);
  }
}

.zakatCarouselContainer {
  width: 86vw;
}

.zakatCarouselItem {
  width: 86vw !important ;
  height: auto !important ;
}

.zakatCarouselClass {
  height: fit-content;
}

.indicators {
  padding-left: 0 !important;
}

.indicator {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #9a9999;
}

.indicator.active {
  background: #1aa7ec;
}

.required {
  &::after {
    content: "*";
    color: red;
  }
}
